.virtualTour
	position: relative
	margin-bottom: 70px

	&:before
		content: ''
		background-color: #000
		width: 50px
		height: 50px
		position: absolute
		bottom: 0
		left: 50%
		transform: translate(-50%, 50%) rotate(45deg)
		z-index: 1
		margin-bottom: -35px

	&-link
		position: relative
		&:after
			content: ''
			position: absolute
			bottom: 0
			left: 0
			height: 35px
			background-color: #fff
			width: 100%
			border-bottom: 1px dotted #000
			z-index: 1
			margin-bottom: -35px
			pointer-events: none

	&-linkTitle
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		z-index: 2
		display: flex
		justify-content: center
		align-items: center
		margin: 0
		font-size: 1.1em
		color: #fff
		background-color: rgba(0, 0, 0, 0.3)
		transition-duration: 0.5s
		text-align: center
		padding: 0 2rem
		line-height: 1
		text-decoration: underline
		&:hover
			background-color: transparent
		@media (min-width: 480px)
			font-size: 1.5em
		@media (min-width: 768px)
			font-size: 2em

	@media(max-width: 768px)
		&:before
			width: 30px
			height: 30px
		&-inner
			padding-bottom: 30px
			&:after
				height: 25px
			&-single
				width: calc(50% - 10px)
				padding-top: calc(40% - 10px)
