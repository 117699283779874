.nav
	position: fixed
	top: 0
	left: 0
	width: 100%
	z-index: 10
	display: flex
	align-items: flex-start
	justify-content: space-between
	padding: 20px 20px 15px 20px
	&-menu
		display: flex
		list-style: none
		padding: 0
		margin: 0
		li:first-child
			display: none
		li
			margin-right: 25px
		li
			color: #fff
			a
				color: #fff
	&.scrolled
		background-color: #fff
		border-bottom: 1px solid #000
		.lngSwitcher
			ul
				li
					border: 1px solid #000
			button
				span
					background-color: #000
	&.scrolled &
		&-menu
			li
				color: #000
				a
					color: #000

	@media(max-width: 768px)
		padding: 10px
		&-menu
			flex-direction: column
			padding: 0
			margin: 0
			button
				border: none
				padding: 0
				//color: #fff
			li:first-child
				display: list-item
			li + li
				margin-left: 0
				margin-top: 10px
			li
				a
					font-size: 12px
					line-height: 1em
					display: block
			li:nth-child(n+2)
				display: none
		&.open &
			&-menu
				li:nth-child(n+2)
					display: block
					&:before
						display: none
