.partners
	&.view-center &
		&-inner
			justify-content: center
	&-inner
		display: flex
		margin: 0 -15px
		align-items: center
		justify-content: space-between
		&-single
			width: calc((100% / 3) - 40px)
			margin: 0 20px
		> a
			display: block
			color: #000
			font-size: 18px
			font-weight: 600
			border-left: 2px solid #000
			padding-left: 20px

	@media(max-width: 768px)
		&-inner
			flex-wrap: wrap
			margin: 0
			&-single
				width: 100%
				margin: 10px 0
			> a
				border-left: none
				padding-left: 0
				margin: 10px 0
				text-decoration: underline
