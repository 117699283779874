.footer
	background-color: #1e1e1e
	padding: 80px 0
	margin-top: 100px
	position: relative
	&:before
		content: ''
		background-color: #fff
		width: 50px
		height: 50px
		position: absolute
		top: 0
		left: 50%
		transform: translate(-50%, -50%) rotate(45deg)
		z-index: 1
	h1, h2, h3, h4, a, p, span
		color: #fff
	&-inner
		display: flex
		justify-content: flex-start
		position: relative
		flex-wrap: wrap
		article
			flex-shrink: 0
			color: #fff
			max-width: 33%
			margin-right: 50px
			p
				margin-top: 0
			a
				display: inline-block
		.socials
			width: 100%
			text-align: center
			//position: absolute
			//left: 50%
			//transform: translateX(-50%)
			h4
				margin-bottom: 20px
			&-inner
				display: flex
				align-items: center
				justify-content: center
				a
					margin: 10px
					position: relative
					display: block
					padding: 5px
					border: 1px solid #fff
					transform: rotate(45deg)
					flex-shrink: 0
					width: 30px
					height: 30px
					position: relative
					svg
						width: 15px
						height: 15px
						transform: rotate(-45deg)
						position: absolute
						top: 50%
						left: 50%
						transform: translate(-50%, -50%) rotate(-45deg)

	@media(max-width: 768px)
		padding: 50px 0
		margin-top: 50px
		&:before
			width: 30px
			height: 30px
		&-inner
			flex-wrap: wrap
			justify-content: flex-start
			.socials
				text-align: left
				position: relative
				width: 100%
			article
				position: relative
				width: 100%
				max-width: 100%
				left: unset
				margin-bottom: 30px
