.textAndImage
	&.left &
		&-inner
			flex-direction: row-reverse
			&-content
				max-width: 350px
				margin: 0 0 0 auto
				padding-right: 40px
				padding-left: 0
				box-sizing: border-box
			&-image
				&:before
					top: 50%
					right: unset
					left: 0
					transform: translate(-50%, -50%) rotate(45deg)
					transition: transform .35s ease-in-out
					z-index: 1
	&-inner
		display: flex
		.container
			padding: 0
		&-image
			width: 50%
			align-items: flex-start
			position: relative
			overflow: hidden
			min-height: 400px
			&:before
				content: ''
				background-color: #fff
				width: 50px
				height: 50px
				position: absolute
				top: 50%
				right: 0
				transform: translate(50%, -50%) rotate(45deg)
				transition: transform .35s ease-in-out
				z-index: 1
		&-content
			max-width: 400px
			margin: 0 auto 0 0
			padding-left: 40px
			box-sizing: border-box
			h2
				img
					height: .65em
					width: auto
					margin-right: 15px
			article
				p
					margin-top: 0

	@media(max-width: 768px)
		&.left &
			&-inner
				&-content
					padding: 0 20px
		&-inner
			flex-wrap: wrap
			&-image
				width: 100%
				margin-bottom: 20px
				padding: 0 20px
				min-height: 200px
				&:before
					display: none
			&-content
				width: 100%
				padding: 0 20px
				max-width: unset
