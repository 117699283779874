.lngSwitcher
	//position: absolute
	//top:15px
	//right: 15px
	z-index: 3
	display: flex
	ul
		flex-shrink: 0
		list-style: none
		padding: 0
		margin: 0 10px 0 0
		display: flex
		flex-direction: column
		&.open
			li
				display: list-item
		li
			order: 2
			margin-bottom: 10px
			display: none
			&:before
				display: none
		li.active
			order: 1
			display: list-item
		a
			display: flex
			cursor: pointer
	button
		width: 15px
		height: 15px
		position: relative
		overflow: hidden
		border: none
		margin-top: -3px
		cursor: pointer
		span
			display: block
			width: 15px
			height: 1px
			background-color: #fff
			transform: rotate(45deg)
			position: absolute
			bottom: 0
			left: 50%
			transform-origin: bottom left
			transform: translate(0, -2px) rotate(-135deg)
		span:nth-child(2)
			transform: rotate(-45deg) translate(1px, -1.5px)
