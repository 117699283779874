@use '_vars'

@import '~sanitize.css'
@import '~sanitize.css/forms.css'
@import '~sanitize.css/assets.css'
@import '~sanitize.css/typography.css'
@import '~sanitize.css/reduce-motion.css'

html,
body
	padding: 0
	margin: 0
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
	font-family: 'Rokkitt', serif
	scroll-behavior: smooth
	scroll-padding: 100px
	p
		font-size: 1.1em
		a
			text-decoration: underline

a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box

h1, h2, h3, h4, h5, h6
	line-height: 1.3em
	margin: 0 0 .3em 0
	font-family: 'Lexend', sans-serif

h1, h2, h3
	font-size: 50px
	@media(max-width: 768px)
		font-size: 32px

.container
	max-width: 700px
	margin: 0 auto
	.container
		padding: 0
	// @media(max-width: 1150px)
	// 	max-width: unset
	// 	padding: 0 40px
	@media(max-width: 768px)
		padding: 0 20px
		max-width: unset

.section + .heading
	margin-top: 100px
.container + .heading
	margin-top: 100px
.container + .section
	margin-top: 100px
.heading + .section
	margin-top: 0
.heading + .image
	margin-top: 0
.content + .heading
	margin-top: 100px
.section + .section
	margin-top: 100px
.anchor + .section
	margin-top: 100px

@media(max-width: 768px)
	.section + .heading
		margin-top: 50px
	.container + .heading
		margin-top: 50px
	.container + .section
		margin-top: 50px
	.content + .heading
		margin-top: 50px
	.heading + .image
		margin-top: 0
	.section + .section
		margin-top: 50px

.heading h2, .footer h2, .gallery h2
	position: relative
	padding-left: 30px
	&:after
		content: ''
		position: absolute
		top: 50%
		left: 0
		width: 20px
		height: 20px
		border-right: 10px solid #000
		border-bottom: 10px solid #000
		transform: rotate(-45deg) translateY(-50%)
	@media(max-width: 768px)
		padding-left: 20px
		&:after
			top: 19px
			width: 12px
			height: 12px
			border-width: 6px
.footer h2
	&:after
		border-color: #fff

@import 'header'
@import 'partners'
@import 'textAndImage'
@import 'gallery'
@import 'footer'
@import 'lngSwitcher'
@import 'nav'
@import 'virtualTour'
