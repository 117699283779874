.gallery
	position: relative
	&-inner
		display: flex
		margin: 0 -5px
		flex-wrap: wrap
		position: relative
		padding-bottom: 40px

		&-single
			width: calc(25% - 10px)
			margin: 5px
			position: relative
			padding-top: calc(20% - 10px)
