.header
	width: 100%
	height: 70vh
	max-height: 1000px
	position: relative
	padding-top: 0!important
	&-inner
		display: flex
		height: 100%
		overflow: hidden
		&-single
			width: 50%
			position: relative
			display: flex
			align-items: center
			justify-content: center
			height: 100%
			cursor: pointer
			a
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				z-index: 2
			&:hover:after
				opacity: 0
			&:hover:before
				transform: translate(-50%, 70%) rotate(45deg)
			&:before
				content: ''
				background-color: #fff
				width: 100px
				height: 100px
				position: absolute
				bottom: 0
				left: 50%
				transform: translate(-50%, 150%) rotate(45deg)
				transition: transform .35s ease-in-out
				z-index: 1
			&:after
				content: ''
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background-color: rgba(0, 0, 0, .6)
				transition: opacity .35s ease-in-out
			&-content
				z-index: 1
				width: 100%
				&-logo
					width: 150px
					margin: 0 auto
					//height: 100%
				h2
					text-align: center
					color: #fff

	@media(max-width: 768px)
		&-inner
			flex-direction: column
			&-single
				width: 100%
				&:before
					display: none
				&-content
					&-logo
						width: 100px
						margin-bottom: 20px
